<template>
  <div class="drag-and-drop-tab">
    <div class="drag-and-drop-container">
      <h3 class="drag-and-drop-container__title">Documents Logo</h3>
      <div class="documents-section">
        <div class="drag-and-drop-content">
          <DragAndDrop v-model="appLogos.documentsLogo" :imageSrc="appLogos.documentsLogo" />
        </div>
        <ul class="documents-section__documents-list">
          <strong>Add Logo to:</strong>
          <li v-for="(item, index) in documentsList" :key="index">
            <label :for="item.value">{{ item.name }}</label>
            <b-form-checkbox
              :id="item.value"
              class="document-checkbox"
              v-model="item.checked"
              @change="checkboxHandler"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DragAndDrop from "@/components/DragAndDrop.vue";

export default {
  name: "DragAndDropTab",
  components: {
    DragAndDrop,
  },
  props: {
    logos: Object,
    documentsOptions: Object,
  },
  data() {
    return {
      appLogos: {
        documentsLogo: "",
      },
      documentsList: [
        {
          name: "Bill of Ladings",
          value: "bol",
          checked: false,
        },
        {
          name: "Labels",
          value: "labels",
          checked: false,
        },
        {
          name: "PDF Quotes Files",
          value: "quote_file",
          checked: false,
        },
      ],
    };
  },
  created() {
    this.appLogos = { ...this.logos };
    this.documentsList.forEach((item) => {
      item.checked = this.documentsOptions[item.value];
    });
  },
  watch: {
    appLogos: {
      deep: true,
      handler(value) {
        if (!value.documentsLogo) {
          return;
        }
        if (value.documentsLogo === this.logos.documentsLogo) {
          return;
        }
        this.$emit("input", {
          ...value,
          documents_logo_options: this.convertDocsArrayToObject(this.documentsList),
        });
      },
    },
  },

  methods: {
    checkboxHandler() {
      this.$emit("input", {
        ...this.appLogos,
        documents_logo_options: this.convertDocsArrayToObject(this.documentsList),
      });
    },
    convertDocsArrayToObject(value) {
      const documentsLogoOptions = {};
      value.forEach((item) => {
        documentsLogoOptions[item.value] = item.checked;
      });
      return documentsLogoOptions;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Checkboxes.scss";

.drag-and-drop-container {
  text-align: left;

  &__title {
    @include font-section-title;
    margin-bottom: 20px;
    color: $color-primary-company;
  }

  &:first-child {
    margin-bottom: 30px;
  }
}

.documents-section {
  display: flex;
  gap: 20px;
  @media (max-width: 500px) {
    flex-direction: column;
    gap: 20px;
  }
  &__documents-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
    margin: 0px;
    list-style-type: none;
    justify-content: space-evenly;
    width: 35%;
    max-width: 285px;
    @media (max-width: 500px) {
      width: 100%;
    }
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      gap: 15px;

      label {
        margin: 0px;
        color: $color-primary-company;
        // -weight: 600;
      }
    }
  }
}
.drag-and-drop-content {
  width: 380px;
}
.drag-and-drop-container:nth-child(2) {
  ::v-deep {
    .drag-and-drop-content {
      width: 50%;

      @media (max-width: 650px) {
        width: 100%;
      }
    }
  }
}

::v-deep {
  .drag-and-drop {
    min-width: none;
  }

  .drag-and-drop__image-container {
    background-color: #cfcfcf;
  }

  .custom-control-label {
    &:before,
    &:after {
      top: 0.1rem;
    }
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: $color-border-container;
  }
}
</style>
